<template>
  <div class="d-flex flex-column video-card">
    <!-- {{ id }} -->
    <div class="outer">
      <div class="text-info-box">
        <div class="card-content">
          <h4>{{ title }}</h4>
          <!-- <img v-if="thumbnails.maxres" :src="thumbnails.maxres.url" alt /> -->
        </div>
      </div>
      <!-- {{thumbnails}} -->
      <img class="inner" v-if="thumbnails.maxres" :src="thumbnails.maxres.url" />
      <img class="inner" v-else-if="thumbnails.high" :src="thumbnails.high.url" />
      <img class="inner" v-else :src="thumbnails.standar.url" />

      <!-- <img v-else :src="thumbnails.high.url"> -->
    </div>
  </div>
</template>

<script>
export default {
  props: ['item', 'id'],
  data() {
    return {
      title: this.item.snippet.title,
      thumbnails: this.item.snippet?.thumbnails,
      videoId: this.item.snippet.resourceId.videoId,
      status: this.item.status.privacyStatus
    }
  }
}
</script>

<style lang="scss">
.video-card {
  height: 100%;
  position: relative;
  overflow: hidden;
  // filter: saturate(40%) hue-rotate(10deg);

  .text-info-box {
    background: transparent;
    position: absolute;
    width: 100%;
    transition: all 0.5s cubic-bezier(0.86, 0, 0.07, 1);
    height: 100%;
    top: 0;
    z-index: 10;

    .card-content {
      position: absolute;
      transform: translateY(calc(100% - 0px));
      padding: 22px;
      bottom: 0;
      width: 100%;
      transition: all 0.5s cubic-bezier(0.86, 0, 0.07, 1);

      h4 {
        text-shadow: 1px 1px 5px #000;
      }
    }
  }

  img,
  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    background: #000;
  }

  &:hover {
    .card-content {
      transform: translateY(0);
    }

    .text-info-box {
      background: rgba($color: #000, $alpha: 0.5);
    }
  }

  .outer {
    position: relative;

    &:before {
      display: block;
      content: "";
      width: 100%;
      padding-top: (9 / 16) * 100%;
    }

    >.inner {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }
  }
}
</style>
